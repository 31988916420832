@import "_vars.scss";

html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;

  @include rtl() {
    font-family: $font-family-regular;
  }
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html[dir="rtl"] {
  direction: rtl;
  height: 100%;

  [class^="col-xs-"],
  [class^="col-sm-"],
  [class^="col-md-"],
  [class^="col-lg-"] {
    float: right;
  }
}

body {
  color: $color-regent-grey;
  background-color: $color-mirage;
  font-family: $font-family-light;
  font-size: 16px;
  height: 100%;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
  }

  a {
    color: $color-viridian;
  }

  p:empty {
    margin: 0;
  }

  button {
    &:focus,
    &:active {
      outline: 0 !important;
    }
  }

  .page {
    height: 100%;
    transition: transform 0.3s;
    width: 100%;
  }

  .additional-content-mobile {
    opacity: 0;
    position: absolute;
    transition: opacity 1s;

    .logo-mobile {
      float: left;
    }

    .alpha {
      color: $color-dusty-grey;
      float: left;
      margin-left: 24px;
      margin-top: 7px;
      width: 34px;

      p {
        font-size: 8px;
        margin-top: 8px;
      }
    }
  }
}

header .globHeaderNavLangSwitcher {
  display: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

::selection {
  background-color: $color-bright-turquoise;
  color: $color-black;
}

input {
  outline: 0;
}

.container {
  width: 100%;
  height: 100%;
  padding: $headerHeight 0 0 0;

  @include breakpoint(md) {
    position: relative;
  }

  @include breakpoint(md, false, true, $smallDesktopHeightBreakpoint) {
    height: 100vh;
  }
}

.sidebar-scroll-only {
  overflow: hidden;

  @include breakpoint(md) {
    overflow: initial;
  }
}

.hide {
  display: none !important;
}

.no-opacity {
  opacity: 0;
}

.nomargin {
  margin: 0;
}

.nopadding {
  padding: 0;
}

.clear-link {
  color: inherit;
  text-decoration: none;
}

.no-padding--desktop {
  @include breakpoint(md) {
    padding: 0;
  }
}

.flex {
  display: flex;
}
