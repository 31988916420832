@import "../../../styles/vars";
@import "../../../styles/mixins";

$headerDividerColor: #25303B;
$wideHeader: 1900px;

.Header {
  height: $headerHeight;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 110;

  &__menu {
    display: flex;
    align-items: center;
  }

  &__divider {
    background-color: $headerDividerColor;
    width: 1px;
    align-self: stretch;
  }

  &__services {
    cursor: pointer;
    user-select: none;
    margin: 12px;
    width: 21px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(md) {
      margin: 17px 24px;
    }

    &__content {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      user-select: none;
    }
  }
}

.Header__container {
  align-items: center;
  background-color: $new-ui-color;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  margin: 0 auto;
  padding: 8px 20px;
  transition: all 0.5s ease;

  @include breakpoint(sm) {
    padding: 8px 22px;
  }

  @include breakpoint(xxl) {
    padding: 16px 108px;
  }

  @include breakpoint(xxxl) {
    padding: 16px 132px;
  }

  @include breakpoint($wideHeader) {
    padding: 16px 156px;
  }

  .logo {
    height: 24px;
  }
}

.Header__logo {
  margin: 0 18px;
  display: flex;
  justify-content: space-between;
  color: $color-white;
  font-size: 14px;
  line-height: 1.4;

  @include breakpoint(md) {
    margin: 0 12px 0 32px;
  }

  @include breakpoint(xxl) {
    margin: 0 12px 0 48px;
  }

  @include breakpoint(xxxl) {
    margin: 0 12px 0 64px;
  }
}

.Header__logo-image {
  margin-left: 10px;

  @include breakpoint(sm) {
    margin: 0 13px;
  }

  @include breakpoint(xxl) {
    margin: 0 13px;
  }

  @include breakpoint(xxxl) {
    margin: 0 13px;
  }
}

.HeaderServices {
  display: none;

  &--active {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 2px solid #9993;
  }

  &__head {
    color: #00f6c9;
    font-family: $font-family-regular;
    font-weight: 400;
    letter-spacing: -0.01em;
    text-align: left;
    text-transform: uppercase;
  }

  .HeaderServicesMenu__service {
    background-color: #161B22;
  }
}
