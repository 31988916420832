@import "../../styles/vars";
@import "../../styles/mixins";

$footerContentWidthMobile: 618px;
$footerContentWidth: 1160px;
$footerDividerColor: #25303B;
$footerBackgroundColor: #192129;

.Footer {
  width: 100%;
  background-color: $footerBackgroundColor;
  padding: 0 20px;

  @include breakpoint(md) {
    padding: 40px 20px 16px;
  }

  &__content {
    margin: auto;
    max-width: $footerContentWidth;
    display: flex;
    flex-direction: column;

    @media (max-width: 1024px) {
      max-width: $footerContentWidthMobile;
    }

    &__section {
      display: flex;
      align-items: flex-end;
      gap: 16px;
      padding: 30px 0;

      &:first-of-type {
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid $footerDividerColor;

        @include breakpoint(md) {
          padding: 40px 0;
          gap: 0;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      }

      @include breakpoint(md) {
        padding: 40px 0;
        gap: 0;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .logo-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        margin-left: auto;

        @include breakpoint(md) {
          margin-left: unset;
        }

        &__divider {
          width: 1px;
          height: 40px;
          background-color: $footerDividerColor;
        }

        &__text {
          color: $color-white;
          font-family: $font-family-regular;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
        }
      }

      .links-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 40px;

        @include breakpoint(md) {
          flex-direction: row;
          align-items: flex-start;
        }

        &__icons {
          display: flex;
          gap: 8px;
        }

        &__links {
          display: flex;
          gap: 24px;

          a {
            text-decoration: none;
            color: $color-white;
            font-family: $font-family-regular;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
    }

    &__section--mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      padding: 30px 0;

      .links-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 16px;

        @include breakpoint(md) {
          margin-left: unset;
        }

        &__icons {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;

          a {
            display: inline-block;
            width: 24px;
            height: 24px;
          }
        }

        &__logo {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;

          &__text {
            color: $color-white;
            font-family: $font-family-regular;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
          }
        }
      }

      .pages-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 40px;

        @include breakpoint(md) {
          flex-direction: row;
          align-items: flex-start;
        }

        &__links {
          display: flex;
          gap: 24px;

          a {
            text-decoration: none;
            color: $color-white;
            font-family: $font-family-regular;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
    }

    &__banner {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;

      @include breakpoint(md) {
        padding-bottom: 0;
      }

      img {
        width: 225px;
      }
    }
  }
}
