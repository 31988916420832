@import "../../../styles/vars";
@import "../../../styles/mixins";

.HeaderServicesMenu {
  position: absolute;
  top: calc($headerHeight - 50px);
  right: -40px;
  display: none;
  cursor: default;

  @include breakpoint(xxl) {
    right: calc(50% - 102px);
  }

  &--active {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 16px 20px;
    border-radius: 6px;
    gap: 16px;
    background-color: $color-white;
    z-index: 2;
  }

  &__head {
    align-self: flex-start;
    color: #6D6E71;
    font-family: $font-family-regular;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: -0.01em;
    text-align: left;
    text-transform: uppercase;
  }
}

.HeaderServicesMenu__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
}

.HeaderServicesMenu__service {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background-color: #F1F1F2;
  border-radius: 4px;
  overflow: hidden;

  img {
    transform: scale(1.5);
    width: 100%;
  }

  &--active {
    background-color: $color-white;
    &:nth-of-type(1) {
      border: 1px solid #7B3AAA;
    }

    &:nth-of-type(2) {
      border: 1px solid #1E60B3;
    }

    &:nth-of-type(3) {
      border: 1px solid #7AC80F;
    }

    &:nth-of-type(4) {
      border: 1px solid $color-bright-turquoise;
    }
  }
}
