@import "../../../styles/vars";
@import "../../../styles/mixins";

$footerActiveLinkColor: #00FAC5;

.FooterNav {
  display: flex;
  gap: 32px;

  @include breakpoint(md) {
    gap: 16px;
  }
}

.FooterNavItem {
  text-decoration: none;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__underline {
      width: 0;
      height: 2px;
      background-color: $color-white;
      &.active {
        width: 100%;
        background-color: $footerActiveLinkColor;
      }
    }

    &__text {
      color: $color-white;
      font-family: $font-family-regular;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-transform: uppercase;
    }
  }
}
