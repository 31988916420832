/**
 * List of colors
 * http://chir.ag/projects/name-that-color/
 */

// White
$color-white: #ffffff;

// Yellow
$color-broom: #fcff25;
$color-chenin: #e2e36c;
$color-lemon: #dfdf6b;

// Grey
$color-bright-gray: #37404e;
$color-tuna: #32373e;
$color-mercury: #e9e9e9;
$color-dusty-grey: #999;
$color-alto: #d3d3d3;
$color-ghost: #cad1da;
$color-regent-grey: #919ba7;
$color-bunker: #11151a;
$color-river-bed: #4e5968;
$color-emperor: #525252;
$color-green-grey: #505252;
$color-ebony-clay: #202934;
$color-fossil-grey: #83868b;
$color-obsidian: #13181f;
$color-dark-grey: #191d23;
$color-trolley-grey: #787b80;
$color-night-rider: #353535;

// Green
$color-carribean-green: #00d57c;
$color-green: #11ee11;
$color-emerald: #53d17a;
$color-parakeet-green: #29e57b;

// Red
$color-red: #ee1111;
$color-scarlet: #ff1f00;
$color-pomegranate: #f64229;
$color-mandy: #e64770;
$color-brilliant-rose: #fe4ea4;
$color-tickle-me-pink: #ff7296;
$color-purple: #c37dc5;

// Blue
$color-shark: #1f252c;
$color-mirage: #1a2027;
$color-ebony-clay: #252a36;
$color-blue-ribbon: #0060ff;
$color-havelock-blue: #4a90e2;
$color-cyan: #00ddff;
$color-bright-turquoise: #00f6c9;
$color-viridian: #4b9584;
$color-oxford-blue: #363e50;
$color-outer-space: #2c333b;
$color-dodger-blue: #00b6ff;

// Black
$color-black: #000000;

/**
  * Main Colors
  */

$color-primary: $color-shark;
$color-success: $color-green;
$color-error: $color-red;

$base-colors: (
  "pink": $color-tickle-me-pink,
  "darkish-pink": $color-mandy,
  "yellow": $color-chenin,
  "turqoise": $color-bright-turquoise,
  "blue": $color-havelock-blue,
  "green": $color-emerald,
  "light-gray": $color-ghost,
  "dark-gray": $color-regent-grey,
  "black": $color-black,
  "white": $color-white,
);

$btn-colors: (
  "turqoise": map_get($base-colors, "turqoise"),
  "blue": map_get($base-colors, "blue"),
  "pink": map_get($base-colors, "darkish-pink"),
  "yellow": map_get($base-colors, "yellow"),
  "green": map_get($base-colors, "green"),
);

$new-ui-color: #192129;

/**
 * General
 */

// Inputs
$color-input-placeholder: $color-shark;
$color-input-text: $color-shark;
$color-input-border: $color-mercury;
$color-input-bg: $color-white;

//Buttons
$color-btn-primary-bg: $color-bright-turquoise;

// List
$color-list-container-border: $color-mercury;
$colot-list-container-text: $color-dusty-grey;
$color-list-container-bg: $color-white;

$color-list-item-text: $color-dusty-grey;
$color-list-item-border: $color-mercury;
$color-list-item-bg: $color-alto;
$color-list-item-bg-hover: $color-mercury;

// Map controls colors
$color-map-control-separator: $color-mercury;
$color-map-control-bg: $color-white;
$color-map-control-shadow: $color-black;

/**
  * Breakpoints
  */
$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 1024px,
  xl: 1200px,
  xxl: 1366px,
  xxxl: 1680px,
  fullHd: 1920px,
);

$smallDesktopHeightBreakpoint: 900px;

/**
 * Fonts
 */

$font-family-regular: "DIN Next LT Arabic", Ubuntu, Helvetica, Calibri,
  sans-serif;
$font-family-medium: "DIN Next LT Arabic Medium", Ubuntu, Helvetica, Calibri,
  sans-serif;
$font-family-light: "DIN Next LT Arabic Light";
$font-icons: "geo-icons";

/**
  * Images
  */
$image-loader: "../assets/loader.gif";

/**
  * Icons
  */
$icon-arrow-right: "\e83e";
$icon-arrow-left: "\e83d";
$icon-cancel: "\e806";
/**
 * Markers
 */
$marker-diameter: 40px;
$marker-dot-diameter: 11px;

/**
 * Sidebar
*/

$sidebar: 1300px;
$sidebar-column: 300px;
$sidebar-graph-spacing: 15px;
$sidebar-space: 8px;

/**
 * Header
*/
$headerHeight: 90px;

$container: (
  desktop: (
    width: 1300px,
    margin-horizontal: 56px,
    margin-vertical: 56px,
    spacing: 40px,
  ),
  small-desktop: (
    width: 1300px,
    margin-horizontal: 50px,
    margin-vertical: 40px,
    spacing: 40px,
  ),
  tablet: (
    width: 100%,
    margin-horizontal: 30px,
    margin-vertical: 60px,
    spacing: 50px,
  ),
  mobile: (
    width: 100%,
    margin-horizontal: 30px,
    margin-vertical: 60px,
    spacing: 30px,
  ),
);

/**
 * Sidebar sector
 */
$sidebar-badge-padding: 11px;

/**
 * Chart panel
*/
$no-sectors-chosen-value: $color-havelock-blue;
$no-sectors-chosen-text: $color-white;
$sticky-menu-bg: $color-ebony-clay;
