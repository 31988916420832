@import "../../styles/mixins";

.logo {
  img {
    height: 36px;

    @include breakpoint(md) {
      height: 48px;
    }
  }
}
