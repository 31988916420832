@import "../../../styles/vars";
@import "../../../styles/mixins";

.HeaderToggle__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 25px;

  @include breakpoint(sm) {
    display: none;
  }
}

.HeaderToggle,
.HeaderToggle:before,
.HeaderToggle:after {
  border: solid 1px $color-white;
  width: 21px;
}

.HeaderToggle {
  position: relative;
  transition: 0.3s all;
}

.HeaderToggle:before,
.HeaderToggle:after {
  content: "";
  left: -1px;
  position: absolute;
  transition: 0.3s all;
}

.HeaderToggle:before {
  top: -7px;
}

.HeaderToggle:after {
  top: 5px;
}

.HeaderToggle--active {
  border: none;
  content: "";
  width: 17px;

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &::before,
  &::after {
    left: -2.5px;
    top: -1px;
    width: 21px;
  }
}
