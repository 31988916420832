@import "../../styles/mixins";
@import "../../styles/vars";

$wideHeader: 1900px;

.HeaderNav {
  top: $headerHeight;
  left: 0;
  position: fixed;
  height: 0;
  width: 100%;
  overflow: hidden;
  padding-left: 45px;
  padding-right: 40px;
  background-image: linear-gradient(to bottom, #0b0f13, #1a2027);
  font-size: 16px;
  transition: all 500ms ease-out;

  @media only screen and (orientation: landscape) {
    overflow: scroll;
  }

  @include breakpoint(sm) {
    flex-direction: row;
    align-items: center;
    position: relative;
    top: inherit;
    left: inherit;
    height: inherit;
    overflow: visible;
    padding-right: 0;
    padding-left: 7px;
    background: none;
    text-align: left;
  }

  @include breakpoint(md) {
    padding-left: 0;
  }

  @media (min-width: 1024px) and (max-width: 1400px) {
    padding-left: 0;
  }

  &.header-menu,
  .header-submenu {
    display: flex;
    flex-direction: column;

    @include breakpoint(sm) {
      flex-direction: row;
    }

    div.header-menu-position {
      padding: 0;

      @include breakpoint(sm) {
        padding: initial;
      }
    }

    .header-menu-position,
    .header-submenu-position {
      text-decoration: none;
      padding: 20px 74px 20px 0;
      color: $color-bright-turquoise;
      line-height: 1.88;
      position: relative;

      &[class*="geo-icon-"]::before {
        position: absolute;
        top: 50%;
        right: 0;
        font-size: 50px;
        transform: translateY(-50%);
        margin: 0;
        width: initial;
      }

      &.geo-icon-homepage::before {
        font-size: 40px;
        right: -5px;
        top: 45%;
      }

      &.geo-icon-allreports::before,
      &.geo-icon-sectors::before,
      &.geo-icon-locations::before,
      &.geo-icon-area::before {
        font-size: 46px;
      }
    }

    .header-menu-position--active,
    .header-submenu-position--active {
      color: $color-viridian;
      text-decoration: underline;

      &[class*="geo-icon-"]::before {
        color: $color-viridian;
        text-decoration: none;
      }

      &::after {
        display: none;
      }
    }
  }

  &.header-menu {
    a.header-toggle-link {
      display: none;
      text-decoration: none;
      color: inherit;

      @include breakpoint(sm) {
        display: inline-block;

        &.active {
          color: $color-bright-turquoise;
          text-decoration: none;
        }
      }

      img {
        filter: invert(91%) sepia(13%) saturate(161%) hue-rotate(174deg)
          brightness(94%) contrast(85%) opacity(0.7);
        transition: all 0.5s ease;
      }
    }

    a.header-toggle-link:first-of-type {
      @include breakpoint(sm) {
        padding-left: 0;
      }
    }

    .header-menu-position {
      $focus-underline-color: rgba($color-bright-turquoise, 0.7);
      $focus-underline-size: 2px;
      &:last-child {
        margin-left: 0;
      }

      @include breakpoint(sm) {
        display: flex;
        align-items: center;
        height: 100%;
        margin: 0 21px;
        padding: 0;
        position: relative;
        color: $color-ghost;
        transition: color 0.5s ease;
        text-decoration: none;

        &--active {
          border-bottom: $focus-underline-size solid $focus-underline-color;
          margin-bottom: -$focus-underline-size;
        }

        &::before {
          display: none;
        }

        &:hover {
          cursor: pointer;
        }

        &:after {
          bottom: 0;
          right: 55%;
          left: 55%;
          content: "";
          height: $focus-underline-size;
          position: absolute;
          background-color: $focus-underline-color;
          transition: all 0.3s ease-in-out 0s;
        }

        &:hover:after {
          left: 0;
          right: 0;
        }

        &:hover img {
          filter: invert(74%) sepia(91%) saturate(1774%) hue-rotate(113deg)
            brightness(96%) contrast(108%) opacity(0.7);
        }
      }
    }
  }

  .header-submenu {
    border-top: solid 2px rgba($color-dusty-grey, 0.2);
    transition: all 0.5s ease;
    padding: 12px 0 0;
    margin-top: 20px;

    @include breakpoint(sm) {
      display: none;

      &.active {
        position: absolute;
        z-index: 2;
        top: 48px;
        left: -11px;
        display: flex;
        flex-direction: column;
        padding: 32px 34px 24px;
        margin-top: 0;
        background-color: $color-mirage;
        border: solid 2px $color-bright-turquoise;
        border-radius: 5px;
        box-shadow: 12px 20px 20px 0 rgba(0, 0, 0, 0.22);
        text-align: right;

        &::before,
        &::after {
          content: "";
          position: absolute;
          height: 0;
          width: 0;
          border-color: transparent transparent $color-bright-turquoise
            transparent;
          border-style: solid;
          border-width: 0 7px 10px 7px;
        }

        &::before {
          left: 9px;
          top: -22px;
          border-color: transparent transparent $color-mirage transparent;
          border-width: 0 18px 24px 18px;
        }

        &:after {
          left: 20px;
          top: -10px;
        }

        a.header-submenu-position {
          min-width: 128px;
          width: 100%;
          padding: 2px 52px 8px 0;
          margin-bottom: 10px;
          color: $color-bright-turquoise;

          &:hover {
            color: $color-viridian;
            text-decoration: underline;

            &::before {
              color: $color-viridian;
              text-decoration: none;
            }
          }

          &::before {
            display: flex;
            font-size: 30px;
            margin: 0 -2px 0 0;
            right: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    @include breakpoint(md) {
      &.active {
        .header-submenu-position {
          a {
            padding-bottom: 8px;
          }
        }
      }
    }

    @include breakpoint(xxl) {
      &.active {
        left: -18px;
        padding: 32px;

        &::before {
          left: 18px;
        }

        &:after {
          left: 29px;
        }

        .header-submenu-position {
          min-width: 124.72px;

          a {
            padding-right: 54px;
            padding-bottom: 8px;

            &:before {
              margin-right: 0;
            }
          }
        }
      }
    }

    @include breakpoint(xxxl) {
      &.active {
        left: -26px;
        padding: 41px;

        .header-submenu-position {
          a {
            padding-bottom: 10px;

            &::before {
              margin: 0;
              right: 0;
              display: flex;
            }
          }
        }
      }
    }

    @include breakpoint($wideHeader) {
      display: none;

      &.active {
        padding: 41px;
        left: -54px;

        &::before {
          left: 48px;
        }

        &:after {
          left: 59px;
        }

        .header-submenu-position {
          a {
            padding-bottom: 8px;
          }
        }
      }
    }
  }
}

.HeaderNav--active {
  height: calc(100% - $headerHeight);
  background: linear-gradient(to top, #1a2027 65%, #0b0e13 100%);
  padding-top: 12px;
  padding-bottom: 40px;
  overflow-y: auto;

  @media only screen and (orientation: landscape) {
    background: linear-gradient(to top, #1a2027 40%, #0b0e13 100%);
  }

  &::before {
    background-color: $color-mirage;
    content: "";
    display: block;
    left: 0;
    padding-bottom: 100vw;
    position: absolute;
    top: 250px;
    transform: rotate(-90deg) skewX(18deg) translateX(0);
    transform-origin: 0 0;
    width: 100vw;
    z-index: -1;

    @media only screen and (orientation: landscape) {
      top: 88vh;
      height: 180vh;
    }
  }
}

.no-scroll {
  overflow: hidden;
}
